/* eslint-disable */
// Plugin to add classes to the body of the page based on which route is mounted.

const IS_HOME_PAGE = 'isHomePage';
const IS_CMS_PAGE = 'isCmsPage';
const IS_PRODUCT_PAGE = 'isProductPage';
const IS_CATEGORY_PAGE = 'isCategoryPage';
const IS_SEARCH_PAGE = 'isSearchPage';
const IS_BUILDER_PAGE = 'isBuilderPage';
const IS_LOGIN_PAGE = 'isLoginPage';
const IS_ACCOUNT_PAGE = 'isAccountPage';
const IS_CHECKOUT_PAGE = 'isCheckoutPage';
const IS_MENU_PAGE = 'isMenuPage';
const IS_CART_PAGE = 'isCartPage';
const IS_FORGOT_PASSWORD_PAGE = 'isForgotPasswordPage';

const addClass = (...classNames) => (args, callback, instance) => {
    const { code = 'berrybean_en_b2b' } = instance.props;
    classNames.forEach(className => {
        if (code === 'berrybean_en_b2b' && (className === IS_LOGIN_PAGE || className === IS_FORGOT_PASSWORD_PAGE)) {
            document.body.classList.add(`${className}B2B`);
        } else {
            document.body.classList.add(className);
        }
    });
    return callback.apply(instance, args);
}

const removeClass = (...classNames) => (args, callback, instance) => {
    const { code = 'berrybean_en_b2b' } = instance.props;
    classNames.forEach(className => {
        if (code === 'berrybean_en_b2b' && (className === IS_LOGIN_PAGE || className === IS_FORGOT_PASSWORD_PAGE)) {
            document.body.classList.remove(`${className}B2B`);
        } else {
            document.body.classList.remove(className);
        }
    });
    return callback.apply(instance, args);
}

export default {
    'Route/HomePage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_HOME_PAGE),
            'componentWillUnmount': removeClass(IS_HOME_PAGE)
        }
    },
    'Route/CmsPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CMS_PAGE),
            'componentWillUnmount': removeClass(IS_CMS_PAGE)
        }
    },
    'Route/ProductPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_PRODUCT_PAGE),
            'componentWillUnmount': removeClass(IS_PRODUCT_PAGE)
        }
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CATEGORY_PAGE),
            'componentWillUnmount': removeClass(IS_CATEGORY_PAGE)
        }
    },
    'Route/SearchPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_SEARCH_PAGE),
            'componentWillUnmount': removeClass(IS_SEARCH_PAGE)
        }
    },
    'Scandipwa/Route/LoginAccount/Container/LoginAccountContainer': {
        'member-function': {
            'componentDidMount': addClass(IS_LOGIN_PAGE),
            'componentWillUnmount': removeClass(IS_LOGIN_PAGE)
        }
    },
    'Scandipwa/Route/ForgotPassword/Container/ForgotPasswordContainer': {
        'member-function': {
            'componentDidMount': addClass(IS_FORGOT_PASSWORD_PAGE),
            'componentWillUnmount': removeClass(IS_FORGOT_PASSWORD_PAGE)
        }
    },
    'Route/MyAccount/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_ACCOUNT_PAGE),
            'componentWillUnmount': removeClass(IS_ACCOUNT_PAGE)
        }
    },
    'Route/Checkout/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CHECKOUT_PAGE),
            'componentWillUnmount': removeClass(IS_CHECKOUT_PAGE)
        }
    },
    'Route/MenuPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_MENU_PAGE),
            'componentWillUnmount': removeClass(IS_MENU_PAGE)
        }
    },
    'Route/CartPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CART_PAGE),
            'componentWillUnmount': removeClass(IS_CART_PAGE)
        }
    }
}